import { useParameters } from "~/composables/Parameters";

export const useSearch = () => {
  const route = useRoute();
  const calculator = useCalculator();
  const storedQuery = useState('storedSearchQuery', () => ({}));

  const search = async (queryString = null, page = 1, countOnly = false, storeParams = true) => new Promise(async resolve => {
    let query = {};

    if (queryString) {
      if (typeof queryString !== "object") {
        if(queryString.indexOf("?") !== -1) {
          queryString = queryString.substr(queryString.indexOf("?"));
        }
        const searchParams = new URLSearchParams(queryString);
        for (const key of searchParams.keys()) {
          query[key] = searchParams.getAll(key);
        }
      } else {
        query = queryString;
      }
    } else {
      query = route.query;
    }

    if(!countOnly) {
      if(storeParams) storedQuery.value = query;

      const stringify = (data) => {
        if(!data) return "";
        if(typeof data.pop === 'function') {
          return data.join(",")
        }
        if(typeof data === 'string') {
          return data;
        }
        return Object.values(data).join(",")
      }

      useAnalytics().analyticsLayer({
        'event': 'search_adv',
        'search_nadwozie': stringify(query.body),
        'search_marka': stringify(query.brand),
        'search_paliwo': stringify(query.fuel),
        'search_skrzynia': stringify(query.gearbox),
        'search_naped': stringify(query.drive),
        'search_moc_od': stringify(query.powerMin),
        'search_moc_do': stringify(query.powerMax),
        'search_miejsca': stringify(query.seats),
        // 'search_promocja': Object.values(query.promo).join(","),
      })
    }

    const req = {}

    const map = {
      brand: "brand",
      body: "bodyType",
      fuel: "fuelType",
      gearbox: "gearboxType",
      drive: "driveType",
      powerMin: "fromPower",
      powerMax: "toPower",
      seats: "seatCount",
      promo:   "labels",
      priceMin: "fromPrice",
      priceMax: "toPrice",
      productionYear: "productionYear"
    }

    Object.keys(query).forEach(key => {
      if (typeof query[key]?.pop !== "undefined") {
        req[map[key]] = query[key].join(",")
      } else {
        req[map[key]] = query[key]
      }
    });

    if (countOnly) {
      req['count'] = 1;
    } else {
      req['offset'] = (page - 1) * 12;
    }

    req['bypass'] = 1;


    if(req['fromPrice']) req['fromPrice'] = Math.round(parseInt(req['fromPrice']) / 1.23)
    if(req['toPrice']) req['toPrice'] = Math.round(parseInt(req['toPrice']) / 1.23)

    console.log('🚀', req);

    const models = await useBackend().search(req);
    if (!countOnly) await calculator.getInstallment(models);
    resolve(models);
  })

  return {
    search
  }
}
