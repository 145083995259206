<script setup>
import { useParameters } from "~/composables/Parameters";

const props = defineProps({
  useUrlQuery: Boolean,
  clearSearch: Boolean
})

const emits = defineEmits(['resultsCountChange']);

const searcher = useSearch();
const {getDefaults, getPromo, getPower, getSeats, getDefaultPrices, getProductionYears} = useParameters();
const {getFiltersDictionary} = useDictionaries();
const route = useRoute();
const breakpoints = useBreakpoints();

const dropDowns = ref([]);

const addToDropDowns = (el) => dropDowns.value.push(el);

const pricesBoundaries = {...getDefaultPrices()}

const values = ref({
  left: getDefaults('priceMin', props.useUrlQuery),
  right: getDefaults('priceMax', props.useUrlQuery)
})

const clearForm = () => {
  dropDowns.value.forEach(el => el.reset());
  values.value.left = null;
  values.value.right = null;
}

const allBrands = await useBackend().getBrands();
const brands = computed(() => allBrands.data.filter(brand => brand.carsCount))
const bodies = await useBackend().getBodies();
const filters = getFiltersDictionary();
const seats = ref(getSeats());
const power = ref(getPower());
const promo = ref(getPromo());
const productionYearOptions = ref(await getProductionYears());
// const gearboxes = await useBackend().getGearBoxes()
// const drives = await useBackend().getDrives();


const drives = await (async () => {
  const api = await useBackend().getDrives();
  const dr = new Map();
  api.data.forEach(d => {
    const i = d.name;
    dr.set(`${i}`, `${i}`)
  })
  return dr;
})()

const gearboxes = await (async () => {
  const api = await useBackend().getGearBoxes();
  const dr = new Map();
  api.data.forEach(d => {
    const i = d.name;
    dr.set(`${i}`, `${i}`)
  })
  return dr;
})()

if (props.clearSearch) {
  useParameters().storedQuery.value = {}
}

const search = ref({
  body: getDefaults('body', props.useUrlQuery),
  productionYear: getDefaults('productionYear', props.useUrlQuery),
  brand: getDefaults('brand', props.useUrlQuery),
  fuel: getDefaults('fuel', props.useUrlQuery),
  gearbox: getDefaults('gearbox', props.useUrlQuery),
  drive: getDefaults('drive', props.useUrlQuery),
  powerMin: getDefaults('powerMin', props.useUrlQuery),
  powerMax: getDefaults('powerMax', props.useUrlQuery),
  seats: getDefaults('seats', props.useUrlQuery),
  promo: getDefaults('promo', props.useUrlQuery),
})

const performSearch = () => {
  const query = {...search.value, priceMin: priceMin.value, priceMax: priceMax.value};
  useParameters().storedQuery.value = query;
  navigateTo({path: '/wyszukaj', query: query}, {external: true})
}

const priceMin = computed(() => values.value.left);
const priceMax = computed(() => values.value.right);

const results = ref(0);
const preSearchCount = async () => {

  const query = {...search.value, priceMin: priceMin.value, priceMax: priceMax.value};
  const data = await searcher.search(query, 1, true);
  return data.data.totalCount;
}

const compare = (base, toCompare, less) => {
  if (search.value[toCompare][0] && base[0]) {
    base = parseInt(toRaw(base)[0]);
    const value = parseInt(search.value[toCompare][0]);

    if (less && base < value || !less && base > value) {
      search.value[toCompare] = [];
    }
  }
}

const analytics = (type, value, isReset) => {

  if (type === 'filter_moc_od' || type === 'filter_moc_do') compare(value, type === 'filter_moc_od' ? 'powerMax' : 'powerMin', false);

  if (!isReset) {
    useAnalytics().analyticsLayer({
      'event': type,
      'filter_type': Object.values(value).join(",")
    });
  }
}

let wait;
watch(() => search.value, async () => results.value = await preSearchCount(), {deep: true})
watch(() => values.value, async () => {
    if (wait) clearTimeout(wait);
    wait = setTimeout(async () => results.value = await preSearchCount(), 200);
  }, {deep: true}
)

onMounted(async () => {
  results.value = await preSearchCount();
  emits('resultsCountChange', results.value);
})

</script>

<template>
  <div class="relative safari-fix-position">
    <div class="mb-2">Parametry techniczne</div>
    <div class="grid grid-cols-4 md:grid-cols-8 xl:grid-cols-16 gap-2 w-full">
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_nadwozie',v, isReset)" v-model="search.body" label="Nadwozie" class="col-span-2" :options="bodies.data" :map="['name','name']" multiple />
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_marka',v, isReset)" v-model="search.brand" label="Marka" :options="brands" :map="['name','name']" class="col-span-2" multiple with-search />
      <InputDropDown :ref="addToDropDowns"
                     @change="(v, isReset) => analytics('filter_rok_produkcji',v,isReset)" v-model="search.productionYear" label="Rok produkcji" class="col-span-2" :options="productionYearOptions"  multiple />
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_paliwo',v, isReset)" v-model="search.fuel" label="Paliwo" :options="filters.fuel" :map="['name','value']" class="col-span-2" multiple />
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_skrzynia',v, isReset)" v-model="search.gearbox" label="Skrzynia biegów" :options="gearboxes"  class="col-span-2" multiple />
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_naped',v, isReset)" v-model="search.drive" label="Napęd" :options="drives" class="col-span-2" multiple />
        <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_miejsca',v, isReset)" v-model="search.seats" label="Ilość miejsc" :options="seats" class="col-span-2" multiple />
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_moc_od',v, isReset)" v-model="search.powerMin" label="Moc od" :options="power" class="col-span-1" />
      <InputDropDown :ref="addToDropDowns" @change="(v, isReset) => analytics('filter_moc_do',v, isReset)" v-model="search.powerMax" label="Moc do" :options="power" class="col-span-1" />
      <!--      <InputDropDown :ref="addToDropDowns" @change="" v-model="search.promo" label="Promocja" :options="promo" class="col-span-2" multiple />-->
    </div>

    <div class="flex flex-wrap justify-between items-center mt-4">
      <div class="md:w-full md:mb-4 lg:mb-0">Cena</div>
      <div class="hidden md:flex font-extralight gap-1 items-baseline lg:order-3">
        <!--        <div class="text-sm">Twoja rata:</div>-->
        <!--        <div class="text-azure">999 - 9 999 zł</div>-->
      </div>
      <InputRange class="w-full lg:w-auto lg:order-2 lg:grow lg:-mt-4 lg:mx-6"
                  :min="pricesBoundaries.min"
                  :max="pricesBoundaries.max"
                  :step="1000"
                  v-model:values="values"
                  fields
                  :compact="!breakpoints.breakpoint.value"
      />
    </div>

    <div class="shadow-box-top -mx-4 sm:-mx-7 -mb-6 px-4 md:px-8 pt-4 pb-4">
      <div class="flex justify-between items-center gap-2 w-full md:justify-end">
        <ButtonPlain tiny class="text-gray-700 grow md:grow-0" @click="clearForm">
          <template v-slot:default>Wyczyść wyszukiwanie</template>
          <template v-slot:rightSlot>
            <IconTrashCan class="!h-4 !w-4 stroke-gray-700" />
          </template>
        </ButtonPlain>
        <ButtonRounded type="gray" class="grow md:grow-0" @click="performSearch" fit>Pokaż modele: {{ results }}</ButtonRounded>
      </div>
    </div>
  </div>
</template>
