<script setup>

const props = defineProps({
  noBanner: Boolean,
  opened: Boolean
})

const emits = defineEmits(['searchResultsCountChange'])

const route = useRoute();
const searchOpenOnStartup = ref(typeof route.query.searchopen !== 'undefined');
const refDrawer = ref();
const drawerVisible = ref(false);

const drawerOpen = () => {
  drawerVisible.value = true;
  refDrawer.value.open();
}

const resultsCountChange = (v) => {
  emits('searchResultsCountChange',v);
  if(searchOpenOnStartup.value) {
    drawerOpen();
    searchOpenOnStartup.value = false;
  }
}

onMounted(() => {
  if (props.opened) {
    setTimeout(() => drawerOpen(), 200);
  }


})

</script>

<template>
  <div>
    <BannerMain v-if="!noBanner" />
    <div class="relative">
      <Brands @searchClick="drawerOpen" :visible="!drawerVisible" />
      <Drawer ref="refDrawer"
              @beforeClose="drawerVisible = false"
              withEscHandler
              overlay>
        <ClientOnly>
          <SearchParameters
            :useUrlQuery="route.name === 'search'"
            :clearSearch="route.name === 'index'"
            @resultsCountChange="resultsCountChange"
          />
        </ClientOnly>
      </Drawer>
    </div>
  </div>
</template>
